<template>
  <div>
    <!-- <b-row align-v="center" align-h="end" class="mb-4">
      <b-col cols="12">
        <filter-fiscal-year-term v-model="formFiscalYearTerm">
        </filter-fiscal-year-term>

        <filter-master
          class="mt-2"
          v-model="formfilter"
          col-headquarter="4"
          col-division="4"
          col-station="4"
          :hiddenInput="{ school: true, schoolClass: true, room: true }"
        >
        </filter-master>

        <filter-date-between
          v-model="filterDateBetween"
          :col-start-date="4"
          :col-end-date="4"
        >
        </filter-date-between>
      </b-col>
    </b-row>

    <b-row v-if="isFetching" align-h="center">
      <loading
        style="height: 250px"
        message="กำลังดาวน์โหลดสรุปภาพรวมผลการประเมิน"
      />
    </b-row> -->

    <b-col cols="12" v-if="survey_answers">
      <div v-if="!noData">
        <b-row v-for="survey_answer in survey_answers" :key="survey_answer.id">
          <b-col class="px-2">
            <h5 class="mb-3">
              <!-- <b> ภาพรวม{{ survey_answer.survey_title }} </b> -->
              <b> ภาพรวมผลการประเมินทั้งหมด</b>
            </h5>

            <div v-if="survey_answer.questions">
              <b-card
                v-for="question in survey_answer.questions"
                :key="question.id"
                class="mb-4"
              >
                <div v-if="question.questions && question.questions[0]">
                  <div class="mb-2 d-flex">
                    <span>
                      <h5 class="mb-0">
                        {{
                          question.questions[0].survey_topic.survey_topic_title
                        }}
                      </h5>
                    </span>
                    <a
                      href="javascript:void(0);"
                      v-b-toggle="
                        `collapse-${question.questions[0].survey_topic.id}`
                      "
                      class="ml-3"
                    >
                      <span class="when-opened">
                        ซ่อนเนื้อหา 🡅
                        <!-- <font-awesome-icon icon="chevron-down" /> -->
                      </span>
                      <span class="when-closed">
                        แสดงเนื้อหา 🡇
                        <!-- <font-awesome-icon icon="chevron-right" /> -->
                      </span>
                    </a>
                  </div>

                  <b-collapse
                    :id="`collapse-${question.questions[0].survey_topic.id}`"
                    visible
                  >
                    <b-col
                      v-for="isQuestionSurveyTopic in question.questions"
                      :key="isQuestionSurveyTopic.id"
                      class="py-2"
                    >
                      <div
                        v-if="isQuestionSurveyTopic.question_type == 'rating'"
                      >
                        <h6 class="text__title">
                          {{ isQuestionSurveyTopic.question_title }}
                        </h6>

                        <div class="text-black-50 ml-3">
                          <!-- <p class="m-0">
                          ผู้ตอบจำนวน:
                          {{
                            isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                              ? isQuestionSurveyTopic.resAns[0].count_answer
                              : 0
                          }}
                          คน
                        </p>
                        <p class="m-0">
                          ค่าสถิติ:
                          {{
                            isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                              ? `${
                                  Math.round(
                                    isQuestionSurveyTopic.resAns[0].avg_rating *
                                      100
                                  ) / 100
                                } `
                              : "0"
                          }}
                        </p>
                        <p class="m-0">
                          ค่าสถิติร้อยละ:
                          {{
                            isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                              ? `${
                                  Math.round(
                                    (isQuestionSurveyTopic.resAns[0]
                                      .avg_rating /
                                      5) *
                                      100 *
                                      100
                                  ) / 100
                                } `
                              : "0"
                          }}
                        </p> -->
                        </div>
                        <div class="row align-items-center">
                          <div class="col-12 col-md-6 col-xl-9">
                            <b-progress
                              height="5px"
                              :style="`background-color: ${genColor50(
                                isQuestionSurveyTopic.resAns &&
                                  isQuestionSurveyTopic.resAns[0]
                                  ? isQuestionSurveyTopic.resAns[0].avg_rating
                                  : 0
                              )}`"
                            >
                              <b-progress-bar
                                :precision="2"
                                :value="
                                  isQuestionSurveyTopic.resAns &&
                                  isQuestionSurveyTopic.resAns[0]
                                    ? isQuestionSurveyTopic.resAns[0].avg_rating
                                    : 0
                                "
                                :max="5"
                                :style="`background-color: ${genColor(
                                  isQuestionSurveyTopic.resAns &&
                                    isQuestionSurveyTopic.resAns[0]
                                    ? isQuestionSurveyTopic.resAns[0].avg_rating
                                    : 0
                                )}`"
                              ></b-progress-bar>
                            </b-progress>
                          </div>
                          <div
                            class="col-12 col-md-6 col-xl-3"
                            :style="`color: ${genColor(
                              isQuestionSurveyTopic.resAns &&
                                isQuestionSurveyTopic.resAns[0]
                                ? isQuestionSurveyTopic.resAns[0].avg_rating
                                : 0
                            )}`"
                          >
                            <p class="m-0">
                              {{
                                isQuestionSurveyTopic.resAns &&
                                isQuestionSurveyTopic.resAns[0]
                                  ? `${
                                      Math.round(
                                        isQuestionSurveyTopic.resAns[0]
                                          .avg_rating * 100
                                      ) / 100
                                    } `
                                  : "0"
                              }}
                              คะแนน ({{
                                isQuestionSurveyTopic.resAns &&
                                isQuestionSurveyTopic.resAns[0]
                                  ? `${
                                      Math.round(
                                        (isQuestionSurveyTopic.resAns[0]
                                          .avg_rating /
                                          5) *
                                          100 *
                                          100
                                      ) / 100
                                    } `
                                  : "0"
                              }}
                              %)
                            </p>
                          </div>
                        </div>
                      </div>

                      <div v-else class="mx-4">
                        <h6 class="text__title">
                          {{ isQuestionSurveyTopic.question_title }}
                        </h6>
                        <!-- <div id="chart">
                        <apexchart
                          type="donut"
                          width="340"
                          height="340"
                          :options="
                            chartOptions(isQuestionSurveyTopic.choice_detail)
                          "
                          :series="series(isQuestionSurveyTopic.choice_detail)"
                        ></apexchart>
                      </div> -->
                        <apexchart
                          type="bar"
                          height="225"
                          width="95%"
                          :options="
                            chartOptions2(isQuestionSurveyTopic.choice_detail)
                          "
                          :series="series2(isQuestionSurveyTopic.choice_detail)"
                        />
                      </div>
                    </b-col>
                  </b-collapse>
                </div>

                <div v-else class="mt-2">
                  <h6 class="col-12 mb-3 text__title">
                    {{ question.question_title }}
                  </h6>

                  <div v-if="question.question_type == 'rating'" class="mb-3">
                    <div class="text-black-50 ml-3">
                      <!-- <p class="m-0">
                        ผู้ตอบจำนวน:
                        {{
                          question.resAns && question.resAns[0]
                            ? question.resAns[0].count_answer
                            : 0
                        }}
                        คน
                      </p>
                      <p class="m-0">
                        ค่าสถิติ:
                        {{
                          question.resAns && question.resAns[0]
                            ? `${
                                Math.round(
                                  question.resAns[0].avg_rating * 100
                                ) / 100
                              } `
                            : "0"
                        }}
                      </p>
                      <p class="m-0">
                        ค่าสถิติร้อยละ:
                        {{
                          question.resAns && question.resAns[0]
                            ? `${
                                Math.round(
                                  (question.resAns[0].avg_rating / 5) *
                                    100 *
                                    100
                                ) / 100
                              } `
                            : "0"
                        }}
                      </p> -->
                    </div>
                    <div class="row align-items-center">
                      <div class="col-10">
                        <b-progress
                          height="5px"
                          :style="`background-color: ${genColor50(
                            question.resAns && question.resAns[0]
                              ? question.resAns[0].avg_rating
                              : 0
                          )}`"
                        >
                          <b-progress-bar
                            :precision="2"
                            :value="
                              question.resAns && question.resAns[0]
                                ? question.resAns[0].avg_rating
                                : 0
                            "
                            :max="5"
                            :style="`background-color: ${genColor(
                              question.resAns && question.resAns[0]
                                ? question.resAns[0].avg_rating
                                : 0
                            )}`"
                          ></b-progress-bar>
                        </b-progress>
                      </div>
                      <div
                        class="col-2"
                        :style="`color: ${genColor(
                          question.resAns && question.resAns[0]
                            ? question.resAns[0].avg_rating
                            : 0
                        )}`"
                      >
                        <p class="m-0">
                          {{
                            question.resAns && question.resAns[0]
                              ? `${
                                  Math.round(
                                    question.resAns[0].avg_rating * 100
                                  ) / 100
                                } `
                              : "0"
                          }}
                          คะแนน ({{
                            question.resAns && question.resAns[0]
                              ? `${
                                  Math.round(
                                    (question.resAns[0].avg_rating / 5) *
                                      100 *
                                      100
                                  ) / 100
                                } `
                              : "0"
                          }}
                          %)
                        </p>
                      </div>
                    </div>
                  </div>
                  <b-col v-else md="" class="mt-3">
                    <!-- <div id="chart">
                      <apexchart
                        type="donut"
                        width="340"
                        height="340"
                        :options="chartOptions(question.choice_detail)"
                        :series="series(question.choice_detail)"
                      ></apexchart>
                    </div> -->
                    <apexchart
                      type="bar"
                      height="225"
                      width="95%"
                      :options="chartOptions2(question.choice_detail)"
                      :series="series2(question.choice_detail)"
                    />
                  </b-col>
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-col v-else>
        <b-row align-v="center" align-h="center" style="height: 320px">
          ไม่พบข้อมูล
        </b-row>
      </b-col>
    </b-col>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapKeys, chain } from "lodash";
import { Auth, Survey } from "../../models";
// import FilterMaster from "../form/FilterMaster";
// import FilterDateBetween from "../form/FilterDateBetween";
// import FilterFiscalYearTerm from "../form/FilterFiscalYearTerm";
// import Loading from "../misc/Loading";

const defaultValue = "ทั้งหมด";

export default {
  components: {
    apexchart: VueApexCharts,
    // FilterMaster,
    // FilterDateBetween,
    // FilterFiscalYearTerm,
    // Loading,
  },

  props: {
    survey_answers: {
      type: Array,
      default: () => {
        [];
      },
    },
  },

  data() {
    return {
      isFetching: false,
      noData: false,
      formfilter: {},

      filterDateBetween: {
        startDate: null,
        endDate: null,
      },

      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },

      value: 4,
      max: 5,
      // survey_answers: [],
      colors: [
        "rgb(234, 72, 77)",
        "rgb(239, 135, 76)",
        "rgb(248, 196, 61)",
        "rgb(159, 205, 53)",
        "rgb(90, 175, 43)",
      ],
      colors_50: [
        "rgb(234, 72, 77, 0.5)",
        "rgb(239, 135, 76, 0.5)",
        "rgb(248, 196, 61, 0.5)",
        "rgb(159, 205, 53, 0.5)",
        "rgb(90, 175, 43, 0.5)",
      ],
      // chartOptions: {
      //   chart: {
      //     width: 380,
      //     type: "donut",
      //   },
      //   labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      // },
    };
  },

  watch: {
    // $route: {
    //   immediate: true,
    //   handler: "onRouteOrFilterChanged",
    // },

    formfilter: {
      deep: true,
      handler: "onRouteOrFilterChanged",
    },

    filterDateBetween: {
      deep: true,
      handler: "onFilterDateBetweenChanged",
    },

    formFiscalYearTerm: {
      deep: true,
      handler: "onFormFiscalYearTermChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    genColor(v) {
      if (v > 0.0 && v < 1.0) {
        return `rgb(118, 118, 118);`;
      }
      if (v >= 1.0 && v < 2.0) {
        return this.colors[0];
      }
      if (v >= 2.0 && v < 3.0) {
        return this.colors[1];
      }
      if (v >= 3.0 && v < 4.0) {
        return this.colors[2];
      }
      if (v >= 4.0 && v < 5.0) {
        return this.colors[3];
      }
      if (v >= 5.0) {
        return this.colors[4];
      }
    },

    genColor50(v) {
      if (v > 0.0 && v < 1.0) {
        return `rgb(118, 118, 118);`;
      }
      if (v >= 1.0 && v < 2.0) {
        return this.colors_50[0];
      }
      if (v >= 2.0 && v < 3.0) {
        return this.colors_50[1];
      }
      if (v >= 3.0 && v < 4.0) {
        return this.colors_50[2];
      }
      if (v >= 4.0 && v < 5.0) {
        return this.colors_50[3];
      }
      if (v >= 5.0) {
        return this.colors_50[4];
      }
    },

    chartOptions(v) {
      let labels = [];
      let count_answer = 0;
      let colors = ["#5aaf2b", "#9fcd35", "#f8c43d", "#ef874c", "#ea484d"];

      for (let index = 0; index < v.length; index++) {
        const element = v[index];
        count_answer +=
          element.answer && element.answer.length > 0
            ? parseInt(element.answer[0].count_answer)
            : 0;
        labels.push(
          element.question_choice_title ? element.question_choice_title : ""
        );
      }

      if (v.length <= 2) {
        colors = ["#5aaf2b", "#ea484d"];
      }

      if (v.length > 2 && v.length <= 3) {
        colors = ["#5aaf2b", "#f8c43d", "#ea484d"];
      }

      return {
        labels: labels,
        colors: colors,
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: { show: true },
                value: { show: false },
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "14px",
                  fontFamily: "Noto Sans Thai, sans-serif",
                  fontWeight: 400,
                  color: "rgba(7, 9, 25, 0.5)",
                  label: count_answer
                    ? `ผู้ตอบจำนวน ${count_answer
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน`
                    : "ไม่มีผู้ตอบแบบสอบถาม",
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "12px",
          fontFamily: "Noto Sans Thai, sans-serif",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return `${value}%`;
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    chartOptions2(v) {
      let labels = [];
      let total_count_answer = 0;
      let colors = ["#5aaf2b", "#9fcd35", "#f8c43d", "#ef874c", "#ea484d"];

      for (let index = 0; index < v.length; index++) {
        const element = v[index];

        total_count_answer +=
          element.answer && element.answer.length > 0
            ? parseInt(element.answer[0].count_answer)
            : 0;

        let question_choice_title = element.question_choice_title || "";

        question_choice_title = question_choice_title
          .replace(/\((.+?)\)/g, "")
          .trim();

        labels.push(question_choice_title);
      }

      if (v.length <= 2) {
        colors = ["#5aaf2b", "#ea484d"];
      }

      if (v.length > 2 && v.length <= 3) {
        colors = ["#5aaf2b", "#f8c43d", "#ea484d"];
      }

      const formatter = function (val) {
        let text = "";

        if (val && val > 0) {
          let percentage = (val / total_count_answer) * 100;

          percentage = parseFloat(percentage.toFixed(2));

          text = `${val
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน (${percentage}%)`;
        }

        return text;
      };

      return {
        chart: {
          stacked: true,
          offsetX: -10,
          offsetY: -10,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            // distributed: true,
            borderRadius: 4,
          },
        },

        colors: colors,

        dataLabels: {
          enabled: true,
          textAnchor: "start",
          // distributed: true,
          formatter,
          style: {
            fontSize: "12px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 2,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: labels,
          title: {
            text: "จำนวน",
            rotate: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
            formatter: function (value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            },
          },
        },

        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
            // offsetY: 10,
          },
          // title: {
          //   text: `${count_answer} คน`,
          //   rotate: 0,
          //   style: {
          //     fontSize: "12px",
          //     fontFamily: "Noto Sans Thai, sans-serif",
          //   },
          // },
        },

        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: 10,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter,
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    // chartOptions2(v) {
    //   let labels = [];
    //   let count_answer = 0;
    //   let colors = ["#5aaf2b", "#9fcd35", "#f8c43d", "#ef874c", "#ea484d"];

    //   for (let index = 0; index < v.length; index++) {
    //     const element = v[index];
    //     count_answer +=
    //       element.answer && element.answer.length > 0
    //         ? parseInt(element.answer[0].count_answer)
    //         : 0;
    //     labels.push(
    //       element.question_choice_title ? element.question_choice_title : ""
    //     );
    //   }

    //   if (v.length <= 2) {
    //     colors = ["#5aaf2b", "#ea484d"];
    //   }

    //   if (v.length > 2 && v.length <= 3) {
    //     colors = ["#5aaf2b", "#f8c43d", "#ea484d"];
    //   }

    //   return {
    //     chart: {
    //       offsetX: -10,
    //       offsetY: -10,
    //       shadow: {
    //         enabled: true,
    //         color: "#000",
    //         top: 18,
    //         left: 7,
    //         blur: 10,
    //         opacity: 1,
    //       },
    //       toolbar: {
    //         show: false,
    //       },
    //     },

    //     plotOptions: {
    //       bar: {
    //         horizontal: true,
    //       },
    //     },

    //     colors: colors,

    //     dataLabels: {
    //       enabled: true,
    //       formatter: function (val) {
    //         return `${val} %`;
    //       },
    //       style: {
    //         fontSize: "14px",
    //         fontFamily: "Noto Sans Thai, sans-serif",
    //         colors: ["#333", "#333"],
    //       },
    //     },

    //     stroke: {
    //       curve: "smooth",
    //       colors: ["transparent"],
    //       width: 10,
    //     },

    //     grid: {
    //       borderColor: "#e7e7e7",
    //     },

    //     markers: {
    //       size: 6,
    //     },

    //     xaxis: {
    //       categories: labels,
    //       title: {
    //         text: `ผู้ตอบจำนวน ${count_answer} คน`,
    //         rotate: 0,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       labels: {
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     yaxis: {
    //       labels: {
    //         show: true,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     legend: {
    //       show: false,
    //       position: "top",
    //       horizontalAlign: "left",
    //       offsetX: 20,
    //       offsetY: -5,
    //       fontSize: "16px",
    //       fontFamily: "Noto Sans Thai, sans-serif",
    //       markers: {
    //         width: 12,
    //         height: 12,
    //         strokeWidth: 0,
    //         strokeColor: "#fff",
    //         radius: 12,
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //     },

    //     tooltip: {
    //       shared: true,
    //       intersect: false,
    //     },

    //     // tooltip: {
    //     //   y: {
    //     //     formatter: function (value) {
    //     //       return `${value} %`;
    //     //     },
    //     //   },
    //     //   style: {
    //     //     fontSize: "14px",
    //     //     fontFamily: "Noto Sans Thai, sans-serif",
    //     //   },
    //     // },
    //   };
    // },

    series(v) {
      let series = [];

      for (let index = 0; index < v.length; index++) {
        const element = v[index];

        let percentage = 0;

        if (element.answer_percentage) {
          percentage = Math.round(element.answer_percentage * 10) / 10;
          percentage = parseFloat(percentage.toFixed(1));
        }

        series.push(percentage);
      }

      return series;
    },

    series2(v) {
      const series = [];
      const lengthSerie = v.length;

      for (let index = 0; index < lengthSerie; index++) {
        const element = v[index];

        let data = new Array(lengthSerie);
        for (let i = 0; i < lengthSerie; ++i) data[i] = 0;

        // let percentage = 0;

        // if (element.answer_percentage) {
        //   percentage = Math.round(element.answer_percentage * 10) / 10;
        //   percentage = parseFloat(percentage.toFixed(1));
        // }

        // data.push(percentage);

        let question_choice_title = element.question_choice_title || "";

        question_choice_title = question_choice_title
          .replace(/\((.+?)\)/g, "")
          .trim();

        let count_answer = 0;

        if (element.answer && element.answer.length > 0) {
          count_answer = parseInt(parseInt(element.answer[0].count_answer));
        }

        data[index] = count_answer;

        series.push({ name: question_choice_title, data });
      }

      return series;
      // return [
      //   {
      //     name: "นักเรียนตามแผน",
      //     data: map(chartData, "total_plan_student").map((x) =>
      //       x ? parseInt(x) : 0
      //     ),
      //   },
      //   {
      //     name: "นักเรียนจริง",
      //     data: map(chartData, "total_actual_student").map((x) =>
      //       x ? parseInt(x) : 0
      //     ),
      //   },
      // ];
    },

    async onRouteOrFilterChanged() {
      // await this.fetch();
    },

    async onFilterDateBetweenChanged() {
      // await this.fetch();
    },

    async onFormFiscalYearTermChanged() {
      // await this.fetch();
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetch() {
      let promise;
      let params = {};
      this.survey_answers = [];

      const { mHeadquarterId, mDivisionId, mStationId, mSchoolId } =
        this.formfilter;

      const { startDate, endDate } = this.filterDateBetween;

      this.noData = false;
      this.isFetching = true;

      if (mHeadquarterId) {
        this.$set(params, "mHeadquarterId", mHeadquarterId);
      }

      if (mDivisionId) {
        this.$set(params, "mDivisionId", mDivisionId);
      }

      if (mStationId) {
        this.$set(params, "mStationId", mStationId);
      }

      if (mSchoolId) {
        this.$set(params, "mSchoolId", mSchoolId);
      }

      if (startDate) {
        this.$set(params, "startDate", startDate);
      }

      if (endDate) {
        this.$set(params, "endDate", endDate);
      }

      try {
        promise = await Survey.api().getDashboard({
          ...params,
          ...this.getFilterTermYear(),
        });

        const { data = [] } = promise.response.data;

        if (data && data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let { questions } = element;

            let tmp_questions = await questions.filter(
              (question) => question.question_type !== "text"
            );

            tmp_questions.sort(function (a, b) {
              var question_no_A = a.question_no,
                question_no_B = b.question_no;
              if (question_no_A < question_no_B) return -1;
              if (question_no_A > question_no_B) return 1;
              return 0;
            });

            let tmpQuestionsAll;

            let newQuestions = tmp_questions.filter(function (question) {
              return question.surveyTopicId == null;
            });

            let tmpQuestions = mapKeys(newQuestions, function (o) {
              return o.question_no;
            });

            let newQuestionsHaveSurveyTopic = tmp_questions.filter(function (
              question
            ) {
              return question.surveyTopicId !== null;
            });

            if (newQuestionsHaveSurveyTopic) {
              let groupBy_newQuestionsHaveSurveyTopic = chain(
                newQuestionsHaveSurveyTopic
              )
                .groupBy("survey_topic.survey_topic_no")
                .map((value, key) => ({ survey_topic: key, questions: value }))
                .value();

              let tmpSurveyTopics = mapKeys(
                groupBy_newQuestionsHaveSurveyTopic,
                function (o) {
                  return o.survey_topic;
                }
              );

              tmpQuestionsAll = { ...tmpQuestions, ...tmpSurveyTopics };
            } else {
              tmpQuestionsAll = { ...tmpQuestions };
            }

            // this.questions = { ...tmpQuestionsAll };

            // console.log(
            //   chain(tmp_questions)
            //     .groupBy("surveyTopicId")
            //     .map((value, key) => ({ surveyTopicId: key, questions: value }))
            //     .value()
            // );

            // let newQuestions = tmp_questions.filter(function (question) {
            //   return question.surveyTopicId == null;
            // });

            // let newQuestionsHaveSurveyTopic = tmp_questions.filter(function (
            //   question
            // ) {
            //   return question.surveyTopicId !== null;
            // });

            // console.log(newQuestions);
            // console.log(newQuestionsHaveSurveyTopic);

            this.survey_answers.push({
              ...element,
              questions: tmpQuestionsAll,
            });
          }
        } else {
          this.noData = true;
        }
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงสรุปข้อมูลแบบประเมินได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  created() {
    // this.fetch = debounce(this.fetch, 500);
  },
  mounted() {
    // this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.text {
  &__title {
    font-size: 1rem;
    font-weight: bold;
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
